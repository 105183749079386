






































































































import moment from 'moment';
import Vue from 'vue';
import Moment from '~/components/common/Moment.vue';
import {Student, Test, TestInstance} from '~/models';

export default Vue.extend({
  components: {Moment},
  name: 'TestDetailPage',
  data() {
    return {
      test: null,
      error: null,
    };
  },
  async created() {
    try {
      this.test = await this.$store.dispatch('test/getTestDetails', this.testId);
      this.error = null;
    } catch (err) {
      if (err?.response?.status === 403) {
        this.error = this.$t('error.notAssigned');
      }
    }
  },
  watch: {
    async testId() {
      try {
        this.test = await this.$store.dispatch('test/getTestDetails', this.testId);
        this.error = null;
      } catch (err) {
        if (err?.response?.status === 403) {
          await this.$store.dispatch('snackbar/showError', this.$t('error.notAssigned'));
          this.$router.go(-1);
        }
      }
    },
  },
  computed: {
    testDependants(): Test[] {
      return this.test.dependants.filter((test: Test) => !!this.$store.state.auth.user.tests.find((t: Test) => t.id === test.id));
    },
    instances(): TestInstance[] {
      const instances = this.test?.instances || [];
      return instances.filter(instance => instance.studentId === this.studentId);
    },
    testId(): number {
      return Number(this.$route.params.testId);
    },
    studentId(): string {
      return String(this.$route.params.studentId);
    },
    student(): Student {
      return Student.find(this.studentId);
    },
    licenceHeaders(): any[] {
      return [
        {text: this.$tc('p.licence', 2), value: 'licence.name', sortable: false},
        {text: this.$tc('p.question', 2), value: 'limit', width: '100px', sortable: false},
      ];
    },
    subjectHeaders(): any[] {
      return [
        {text: this.$tc('p.subject', 2), value: 'subject.name', sortable: false},
        {text: this.$tc('p.question', 2), value: 'limit', width: '100px', sortable: false},
      ];
    },
    areaHeaders(): any[] {
      return [
        {text: this.$tc('p.area', 2), value: 'area.name', sortable: false},
        {text: this.$tc('p.question', 2), value: 'limit', width: '100px', sortable: false},
      ];
    },
    subAreaHeaders(): any[] {
      return [
        {text: this.$tc('p.subArea', 2), value: 'subArea.name', sortable: false},
        {text: this.$tc('p.question', 2), value: 'limit', width: '100px', sortable: false},
      ];
    },
    questionHeaders(): any[] {
      return [
        {text: this.$tc('p.staticQuestion', 2), value: 'text', sortable: false},
      ];
    },
    instanceHeaders(): any[] {
      return [
        {text: this.$tc('label.student'), value: 'student'},
        {text: this.$tc('p.try'), value: 'try', align: 'right'},
        {text: this.$tc('p.correctAnswer', 2), value: 'correctAnswers', align: 'right'},
        {text: this.$t('label.percentage'), value: 'passRate', align: 'right'},
        {text: this.$t('label.duration'), value: 'duration', align: 'right', sortable: false},
        {text: this.$t('label.passed'), value: 'passed', align: 'center'},
        {text: this.$t('label.submittedAt'), value: 'finishedAt', align: 'right'},
      ];
    },
  },
  methods: {
    getPercentage(item: TestInstance) {
      return Math.floor(item.correctAnswers / item.questionAmount * 100);
    },
    getDuration(item: TestInstance) {
      const diff = moment(item.finishedAt).diff(item.createdAt);
      return moment.utc(diff).format('HH:mm:ss');
    },
    getPassRate(test: Test): number {
      return test.isPreExam ? this.$store.state.auth.school.passRate || test.passRate : test.passRate;
    },
    getColorClass(item: TestInstance) {
      if (item.passed) {
        return 'instance-green';
      }
      if (item.try < item.tries) {
        return 'instance-orange';
      }

      const latestTestInstance = this.instances.filter((i: TestInstance) => i.studentId === item.studentId).sort((a, b) => a.try < b.try ? 1 : a.try > b.try ? -1 : 0)[0];
      if (latestTestInstance.id === item.id) {
        return 'instance-red';
      }

      return 'instance-orange';
    },
  },
});
